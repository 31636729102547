<template>
  <b-card no-body class="all-images mt-2 h-100 bg-dark-navy custom border-0">
    <template v-slot:header>
      <b-row class=" mb-0" >
        <b-col cols="8" >
          <font-awesome-icon :icon="getIcon()" size="lg" class="mr-1"></font-awesome-icon> {{ getPhase() }}
        </b-col>
        <b-col  cols="4" align-self="center" class="text-right">
          <h6 class="color-white pt-0 mb-0">{{ projects.find((project) => project._id === projectId).name }}</h6>
        </b-col>
      </b-row>
    </template>
    <b-card-body class="h-100 p-0">
      <b-tabs card pills nav-wrapper-class="nav-wrapper rounded-0 border-0 pt-0" content-class="overflow-y scroll-overflow h-100 dashboards" fill v-model="tabIndex">
        <b-tab title="Reader Summary" active>
          <div class="px-3 py-2 h-100" v-if="tabIndex === 0">
            <b-row align-h="between" class="pb-0 mb-0">
              <b-col align-self="end" cols="9">
                <h6 class="text-white ml-2 mt-2">Reader Summary</h6>
              </b-col>
              <b-row cols="3" class="py-1 mb-0 mx-1 px-2">
                <b-col cols="6" class="py-0 mx-0 px-1 d-flex">
                  <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill mr-2" :text="threshold.toFixed(2)" variant="warn">
                    <b-dropdown-item v-for="value in Array.from({ length: 19 }, (_, i) => (i + 1) * 0.05)" :key="'threshold_' + value" @click="threshold = value">{{ value.toFixed(2) }}</b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill" :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                    <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                    <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{ sg.name }}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-row>
            <hr class="mt-0 border-info" />
            <b-row>
              <b-col cols="4" class="mt-2">
                <apexchart height="320" :options="variabilityOptions" :series="variabilitySeries"></apexchart>
              </b-col>
              <b-col cols="4" class="mt-2">
                <apexchart height="320" :options="readerOptions" :series="readerSeries"></apexchart>
              </b-col>
              <b-col cols="4" class="mt-2">
                <apexchart height="320" :options="moderatorOptions" :series="moderatorSeries"></apexchart>
              </b-col>
              <b-col cols="12" class="mt-2">
                <apexchart height="100" :options="dataOptions" :series="dataSeries" ></apexchart>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="AI Performance Summary">
          <div class="px-3 py-2" v-if="tabIndex === 1">
            <b-row align-h="between" class="pb-0 mb-0">
              <b-col align-self="end" cols="9">
                <h6 class="text-white ml-2 mt-2">Reader Summary</h6>
              </b-col>
              <b-row cols="3" class="py-1 mb-0 mx-1 px-2">
                <b-col cols="6" class="py-0 mx-0 px-1 d-flex">
                  <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill mr-2" :text="threshold.toFixed(2)" variant="warn">
                    <b-dropdown-item v-for="value in Array.from({ length: 19 }, (_, i) => (i + 1) * 0.05)" :key="'threshold_' + value" @click="threshold = value">{{ value.toFixed(2) }}</b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill" :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                    <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                    <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{ sg.name }}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-row>
            <hr class="mt-0 border-info" />
            <b-row>
              <b-col cols="4" class="mt-2">
                <apexchart height="320" :options="cmOptions" :series="cmSeries"></apexchart>
              </b-col>
              <b-col cols="4" class="mt-2">
                <apexchart :options="aucOptions" :series="aucSeries"></apexchart>
              </b-col>
              <b-col cols="4" class="mt-2">
                <apexchart :options="prOptions" :series="prSeries"></apexchart>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="AI Threshold Analysis">
          <div class="px-3 py-2" v-if="tabIndex === 2">
            <b-row align-h="between" class="pb-0 mb-0">
              <b-col align-self="end" cols="9">
                <h6 class="text-white ml-2 mt-2">AI Threshold Analysis</h6>
              </b-col>
              <b-row cols="3" class="py-1 mb-0 mx-1 px-2">
                <b-col cols="6" class="py-0 mx-0 px-1 d-flex">
                  <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill mr-2" :text="threshold.toFixed(2)" variant="warn">
                    <b-dropdown-item v-for="value in Array.from({ length: 19 }, (_, i) => (i + 1) * 0.05)" :key="'threshold_' + value" @click="threshold = value">{{ value.toFixed(2) }}</b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown class="btn px-3 btn-sm btn-yellow rounded-pill" :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                    <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                    <b-dropdown-item v-for="sg in project.subgroups" :key="sg.name" @click="filterChoice = sg.name">{{ sg.name }}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-row>
            <hr class="mt-0 border-info" />
            <b-table striped hover :items="metrics">
              <template #cell(threshold)="data">
                <small>{{ data.item.threshold.toFixed(2) }}</small>
              </template>
              <template #cell(TP)="data">
                <b-badge pill class="bg-green color-black py-1 px-2 font-weight-400">
                  {{ data.item.TP }}
                </b-badge>
              </template>
              <template #cell(FP)="data">
                <b-badge pill class="bg-red color-white py-1 px-2 font-weight-400">
                  {{ data.item.FP }}
                </b-badge>
              </template>
              <template #cell(FN)="data">
                <b-badge pill class="bg-red color-white py-1 px-2 font-weight-400">
                  {{ data.item.FN }}
                </b-badge>
              </template>
              <template #cell(TN)="data">
                <b-badge pill class="bg-green color-black py-1 px-2 font-weight-400">
                  {{ data.item.TN }}
                </b-badge>
              </template>
              <template #cell(total)="data">
                <small>{{ data.item.total.toFixed(0) }}</small>
              </template>
              <template #cell(accuracy)="data">
                <small>{{ data.item.accuracy.toFixed(2) }}</small>
              </template>
              <template #cell(sensitivity)="data">
                <small>{{ !Number.isNaN(data.item.sensitivity) ? data.item.sensitivity.toFixed(2) : '-' }}</small>
              </template>
              <template #cell(specificity)="data">
                <small>{{ !Number.isNaN(data.item.specificity) ? data.item.specificity.toFixed(2) : '-' }}</small>
              </template>
              <template #cell(TPR)="data">
                <small>{{ data.item.TPR >= 0 ? data.item.TPR.toFixed(2) : '-' }}</small>
              </template>
              <template #cell(NPV)="data">
                <small>{{ data.item.NPV >= 0 ? data.item.NPV.toFixed(2) : '-' }}</small>
              </template>
              <template #cell(FPR)="data">
                <small>{{ data.item.FPR >= 0 ? data.item.FPR.toFixed(2) : '-' }}</small>
              </template>
              <template #cell(F1)="data">
                <small>{{ data.item.F1 >= 0 ? data.item.F1.toFixed(2) : '-' }}</small>
              </template>
              <template #cell(recall)="data">
                <small>{{ data.item.recall >= 0 ? data.item.recall.toFixed(2) : '-' }}</small>
              </template>
              <template #cell(precision)="data">
                <small>{{ data.item.precision >= 0 ? data.item.precision.toFixed(2) : '-' }}</small>
              </template>
              <template #cell(error)="data">
                <small>{{ data.item.error >= 0 ? data.item.error.toFixed(2) : '-' }}</small>
              </template>
              <template #cell(_95_CI)="data">
                <small>{{ data.item._95_CI >= 0 ? data.item._95_CI.toFixed(4) : '-' }}</small>
              </template>
            </b-table>
          </div>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<style>
.scrollable {
  overflow-y: auto;
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
import { IOVThesholds } from './../../../../helpers/iovthresholds'

export default {
  name: 'SummaryResults',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    selected: {
      type: Number,
      default: null
    },
    projectId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      phases: {
        retrospective: [
          { id: 10, stub: 'setup', name: 'Project Details', icon: 'sitemap' },
          { id: 5, stub: 'setup', name: 'Project set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          { id: 2, stub: 'preprocessing', name: 'Data pre-processing', icon: 'clone' },
          { id: 3, stub: 'annotation', name: 'Data annotation', icon: 'crop' },
          { id: 4, stub: 'truth', name: 'Reference standard', icon: 'check-double' },
          { id: 6, stub: 'run', name: 'AI Processing', icon: 'hourglass-start' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ],
        prospective: [
          { id: 5, stub: 'setup', name: 'Project set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          { id: 10, stub: 'feedback', name: 'Inference feedback', icon: 'crop' },
          { id: 11, stub: 'moderation', name: 'Feedback moderation', icon: 'check-double' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ]
      },
      expand: false,
      options: {
        chart: {
          height: 300,
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '24px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '12px'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 60, 100]
          }
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        yaxis: {
          min: 0,
          max: 1,
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        }
      },
      pieOptions: {
        chart: {
          type: 'donut',
          toolbar: {
            show: false
          }
        },
        title: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          offsetY: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '20px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '10px'
          }
        },
        legend: {
          position: 'left',
          offsetY: 50,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
          }
        },
        dataLabels: {
          style: {
            fontFamily: 'Lato, sans-serif',
            fontSize: '8px'
          }
        },
        colors: ['#28a745', '#ffc107', '#dc3545', '#17a2b8', '#2673bc'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 100
            },
            legend: {
              position: 'top'
            }
          }
        }]
      },
      barOptions: {
        chart: {
          type: 'bar',
          height: 100,
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          floating: true,
          margin: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '14px'
          }
        },
        fill: {
          opacity: 1
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: true,
          offsetY: 9,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: '#ffffff'
          }
        }
      },
      imgAiData: [],
      imgData: [],
      proImg: [],
      fa: 0,
      pd: 0,
      fd: 0,
      conReader: 0,
      disReader: 0,
      conMod: 0,
      disMod: 0,
      accuracy: [],
      rejections: [],
      trueP: 0,
      falseP: 0,
      falseN: 0,
      IOV: 0,
      notAnnotated: 0,
      partiallyAnnotated: 0,
      fullyAnnotated: 0,
      moderated: 0,
      processed: 0,
      failProcessed: 0,
      aiMetrics: [],
      filterChoice: '',
      filterChoicePathArray: {},
      filterChoicePathArray2: [],
      tabIndex: 0,
      threshold: 0.5
    }
  },
  computed: {
    ...mapState({
      projects: (state) => state.projectSubmission.projects,
      userProjects: (state) => state.projectSubmission.projects
    }),
    ...mapState('labelledImagesSubmission', {
      images: (state) => state
    }),
    ...mapState('projectSubmission', {
      _project: (state) => state
    }),
    ...mapState('usersSubmission', { members: (state) => state }),
    getAi () {
      return this.images.aiLoaded
    },
    project () {
      const filteredProject = this.userProjects.find(
        (project) => project._id === this.projectId
      )
      return filteredProject
    },
    imagesLoaded () {
      return this.images.loaded
    },
    reducedImages () {
      this.filterChoicePathArray2.forEach(_key => {
        // console.log('key', _key)
      })
      var _images = this.imgData
      if (this.filterChoice !== 'All' && this.filterChoice !== '') {
        _images = _images.filter(img => this.hasGroup(img, this.filterChoice))
      }
      return _images
    },
    variabilityOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Full Agreement', 'Partial Disagreement', 'Full Disagreement']
      _options.colors = ['#28a745', '#ffc107', '#dc3545']
      _options.title.text = !this.IOV ? 'Not Enough Data' : (this.IOV * 100).toFixed(2) + '%'
      _options.subtitle.text = 'Inter-Observer Variability'

      return _options
    },
    variabilitySeries () {
      return [this.fa, this.pd, this.fd]
    },
    readerOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Fully Annotated', 'Partially Annotated', 'Not Annotated']
      _options.colors = ['#28a745', '#ffc107', '#dc3545']
      _options.title.text = 'Data Annotation'
      _options.subtitle.text = 'Progress Summary'

      return _options
    },
    readerSeries () {
      return [this.fullyAnnotated, this.partiallyAnnotated, this.notAnnotated]
    },
    moderatorOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Moderated', 'Rejected']
      _options.colors = ['#28a745', '#dc3545']
      _options.title.text = 'Data Moderation'
      _options.subtitle.text = 'Progress Summary'
      return _options
    },
    moderatorSeries () {
      return [this.moderated, this.rejections.reduce((a, b) => a + b.count, 0)]
    },
    dataOptions () {
      const _options = JSON.parse(JSON.stringify(this.barOptions))
      _options.title.text = undefined

      return _options
    },
    dataSeries () {
      return [{
        name: 'Processed',
        data: [this.processed]
      }, {
        name: 'Failed during Processing',
        data: [0]
      }, {
        name: 'Rejected on Data Moderation',
        data: [this.rejections.find(r => r.phase === 3) ? this.rejections.find(r => r.phase === 3).count : 0]
      }, {
        name: 'Partially Annotated',
        data: [this.partiallyAnnotated]
      }, {
        name: 'Not Annotated',
        data: [this.notAnnotated]
      }, {
        name: 'Rejected on Data Processing',
        data: [this.rejections.find(r => r.phase === 2) ? this.rejections.find(r => r.phase === 2).count : 0]
      }, {
        name: 'Rejected on Reference Standard',
        data: [this.rejections.find(r => r.phase === 4) ? this.rejections.find(r => r.phase === 4).count : 0]
      }, {
        name: 'Rejected on Data Collection',
        data: [this.rejections.find(r => r.phase === 1) ? this.rejections.find(r => r.phase === 1).count : 0]
      }]
    },
    cmOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['True Positive', 'False Positive', 'False Negative', 'True Negative']
      _options.title.text = (this.confusionMatrix.truePositive + this.confusionMatrix.falsePositive +
      this.confusionMatrix.falseNegative + this.confusionMatrix.trueNegative === 0 ? 'Not Enough Data' : (Math.round(
          (this.confusionMatrix.truePositive + this.confusionMatrix.trueNegative) /
        (this.confusionMatrix.truePositive + this.confusionMatrix.falsePositive +
        this.confusionMatrix.falseNegative + this.confusionMatrix.trueNegative) * 10000) / 100).toFixed(2) + '%')
      _options.subtitle.text = 'Accuracy of AI Solution'

      return _options
    },
    cmSeries () {
      return [this.confusionMatrix.truePositive, this.confusionMatrix.falsePositive, this.confusionMatrix.falseNegative, this.confusionMatrix.trueNegative]
    },
    auc () {
      const _thresholds = JSON.parse(JSON.stringify(this.metrics.filter(__metrics => __metrics.threshold !== 0)))
      const _metrics = []

      if (_thresholds && _thresholds.length > 0) {
        _metrics.push({
          threshold: 0,
          FPR: 1,
          TPR: 1,
          AUC: (1 - _thresholds[0].FPR) * 1
        })

        for (let i = 0; i < _thresholds.length; i++) {
          _metrics.push({
            threshold: _thresholds[i].threshold,
            FPR: _thresholds[i].FPR,
            TPR: _thresholds[i].TPR,
            AUC: (_thresholds[i].FPR - (_thresholds[i + 1] ? _thresholds[i + 1].FPR : 0) - 0.003175) * _thresholds[i].TPR
          })
        }
      }

      let _sum = 0
      if (_metrics.filter(_record => _record.FPR !== null && _record.TPR !== null).length > 1) {
        _metrics.forEach(_metric => {
          _sum += _metric.AUC
        })
      }

      return _sum
    },
    aucOptions () {
      const _options = JSON.parse(JSON.stringify(this.options))
      _options.xaxis.type = 'numeric'
      _options.xaxis.min = 0
      _options.xaxis.max = 1
      _options.xaxis.categories = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95]
      _options.title.text = this.auc.toFixed(4)
      _options.subtitle.text = 'Area Under Curve'

      return _options
    },
    aucSeries () {
      const _sorted = this.metrics.slice()
      _sorted.sort((a, b) => { return a.FRP - b.FRP })

      return [{
        data: _sorted.map(__metrics => { return { x: __metrics.FPR, y: (__metrics.TPR > 1 ? __metrics.TPR : __metrics.TPR) } })
      }]
    },
    prOptions () {
      const _options = JSON.parse(JSON.stringify(this.options))
      _options.xaxis.type = 'numeric'
      _options.xaxis.min = 0
      _options.xaxis.max = 1
      _options.xaxis.categories = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95]
      _options.title.text = this.metrics.find(__metrics => __metrics.threshold === 0.8) ? this.metrics.find(__metrics => __metrics.threshold === 0.8).accuracy.toFixed(4) : 0
      _options.subtitle.text = 'Precision-Recall Curve'

      return _options
    },
    prSeries () {
      return [{
        data: this.metrics.map(__metrics => { return { x: __metrics.precision, y: __metrics.recall } })
      }]
    },
    rejectWatch () {
      return !this._project.loadingRejects
    },
    readers () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    moderators () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Moderator') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    projectImagesLoaded () {
      return !this._project.loadingProjectImages
    },
    confusionMatrix () {
      const _at50 = this.aiMetrics.find(_record => _record.threshold === Math.round(this.threshold * 100) / 100)

      if (_at50) {
        return {
          truePositive: _at50.TP,
          falsePositive: _at50.FP,
          falseNegative: _at50.FN,
          trueNegative: _at50.TN
        }
      } else {
        return {
          truePositive: 0,
          falsePositive: 0,
          falseNegative: 0,
          trueNegative: 0
        }
      }
    },
    metrics () {
      return this.aiMetrics
    },
    dataLoaded () {
      return (this.getAi && this.projectImagesLoaded && this.imagesLoaded && this.rejectWatch)
    }
  },
  mounted () {
    // // console.log('ab')
    this.imgAiData = this.images.aiImages.filter(img => (!img.image.removed.find(p => p.project === this.project._id)))
    this.proImg = this._project.projectImages
    this.imgData = this.images.labelledImages.filter(img => (!img.image.removed.find(p => p.project === this.project._id)))
    this.rejections = this._project.rejects
    this.getChartData()
    this.calcMetrics()
  },
  methods: {
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2', 'fetchAILabelledImages']),
    ...mapActions('projectSubmission', ['getProjectImages2', 'getRejections2']),
    getChartData () {
      if (this.getAi && this.projectImagesLoaded && this.imagesLoaded && this.rejectWatch) {
        this.fa = 0
        this.pd = 0
        this.fd = 0
        this.moderated = 0
        this.notAnnotated = 0
        this.partiallyAnnotated = 0
        this.fullyAnnotated = 0
        // console.log(this.getAi)
        // console.log(this.projectImagesLoaded)
        // console.log(this.imagesLoaded)
        // console.log(this.rejectWatch)
        var runningAcc = 0
        var totalAcc = 0
        this.reducedImages.forEach(img => {
          if (!img.image.removed.find(p => p.project === this.project._id)) {
            if (img.users.find(us => this.moderators.find(mod => mod._id === us._id))) {
              // console.log('mod')
              this.moderated++
              this.fullyAnnotated++
            } else if (img.pathologies.length > 0 && img.users.length < this.readers.length && img.users.length > 0) {
              this.partiallyAnnotated += 1
            } else if (img.pathologies.length > 0 && img.users.length > 0 && img.users.length === this.readers.length) {
              this.fullyAnnotated++
            }
            var acc
            if (img.image.accuracy.filter(a => a.project === this.project._id).length > 0) {
              acc = img.image.accuracy.filter(a => a.project === this.project._id).slice(-1)[0].value
            } else {
              acc = 0
            }
            var _acc = []
            if (img.otherImages && img.otherImages.length > 0 && img.image.image_set && img.image.image_set.name) {
              img.otherImages.forEach(_img => {
                const _accuracy = _img.image.accuracy.filter(a => a.project === this.project._id)
                if (_accuracy && _accuracy.length > 0) {
                  _acc.push(_accuracy.slice(-1)[0].value)
                }
              })
            }
            // console.log('acc', acc)
            _acc.push(acc)
            // // console.log('all acc', _acc)
            _acc = _acc.filter(a => a !== 0)
            if (_acc.length > 0) {
              // // console.log('av  acc', _acc.reduce((a, b) => a + b) / _acc.length)
              acc = _acc.reduce((a, b) => a + b) / _acc.length
            } else {
              acc = 0
            }
            if (img.pathologies.length > 0 && img.users.length >= this.readers.length) {
              runningAcc += acc
              totalAcc += 1
              // console.log('acc', acc)
              if (acc <= IOVThesholds().fullAgreement) {
                this.fa++
              } else if (acc >= IOVThesholds().fullAgreement && acc <= IOVThesholds().partialAgreement) {
                this.pd++
              } else {
                this.fd++
              }
            }
            var mpu = 0
            var pathCount = 0
            img.pathologies.forEach(p => {
              var runningPath = 0
              Object.keys(p).forEach(pkey => {
                if (pkey !== 'created_by') {
                  runningPath++
                }
              })
              pathCount += runningPath
              mpu = mpu < runningPath ? runningPath : mpu
            })
            var aiImg = this.imgAiData.find(i => img.image._id === i.image._id)
            // // console.log('ai', aiImg)
            if (aiImg) {
              this.processed += 1
              var aiCount = Object.keys(aiImg.pathologies[0]).length - 1
              // // console.log('abc', pathCount)
              // // console.log('ai', aiCount)
              // aiCount === pathCount ? 'True Positive' : aiCount > pathCount ? 'False Positive' : 'False Negative'
              if (aiCount === pathCount) {
                this.trueP++
              } else if (aiCount > pathCount) {
                this.falseP++
              } else {
                this.falseN++
              }
            }
          }
        })
        this.IOV = runningAcc / totalAcc
        this.notAnnotated = this.reducedImages.length - this.fullyAnnotated - this.partiallyAnnotated
      }
      // // console.log('chart')
    },
    getPhase: function () {
      if (this.projectId) {
        const _project = this.list.find(_project => _project._id === this.projectId)
        const _phase = this.phases[_project.type.toLowerCase()].find(_phase => _phase.id === this.selected)
        return _phase.name
      } else {
        return ''
      }
    },
    getIcon: function () {
      if (this.projectId) {
        const _project = this.list.find(_project => _project._id === this.projectId)
        // console.log('_phase', _project.type.toLowerCase())
        const _phase = this.phases[_project.type.toLowerCase()].find(_phase => _phase.id === this.selected)
        return _phase.icon
      } else {
        return ''
      }
    },
    calcMetrics: function () {
      if (this.getAi && this.projectImagesLoaded && this.imagesLoaded && this.rejectWatch) {
        const _metrics = []

        const _temp = []
        for (let i = 0.05; i < 1; i += 0.05) {
          _temp.push({
            threshold: Math.round(i * 100) / 100
          })
        }

        _temp.forEach(__record => {
          const __metrics = {
            threshold: __record.threshold,
            TP: 0,
            FP: 0,
            FN: 0,
            TN: 0
          }

          this.reducedImages.forEach(img => {
            if (!img.image.removed.find(p => p.project === this.project._id)) {
              const _thisImage = this.proImg.find(_record => _record._id === img.image._id)
              const _moderated = img.users.filter(_record => _record.projectRole.find(__record => __record.project === this.projectId && __record.role === 'Moderator'))
              let _users = this.readers.map(_user => _user._id)
              if (_moderated.length > 0) {
                _users = this.moderators.map(_user => _user._id)
              }
              if (img.pathologies.length > 0 && img.users.length >= this.readers.length && _thisImage) {
                var mpu = 0
                // var pathCount = 0
                img.pathologies.filter(_record => _users.indexOf(_record.created_by) >= 0).forEach(p => {
                  var runningPath = 0
                  Object.keys(p).forEach(pkey => {
                    if (pkey !== 'created_by') {
                      runningPath++
                    }
                  })
                  // pathCount += runningPath
                  mpu = mpu < runningPath ? runningPath : mpu
                })
                var aiImg = this.imgAiData.find(i => img.image._id === i.image._id)
                // // console.log('ai', aiImg)
                if (aiImg) {
                  const _aiAccuracy = _thisImage.ai_accuracy.find(_record => _record.project === this.projectId)
                  const _positive = _aiAccuracy && _aiAccuracy.value ? _aiAccuracy.value > 0.00000001 : false
                  var aiCount = 0
                  Object.keys(aiImg.pathologies[0]).forEach(aiP => {
                    if (aiP !== 'created_by' && aiImg.pathologies[0][aiP].name !== 'heart' && aiImg.pathologies[0][aiP].name !== 'apchest') {
                      if (aiImg.pathologies[0][aiP].confidence >= __record.threshold) {
                        aiCount++
                      }
                    }
                  })
                  // console.log('AI', _aiAccuracy.value, _positive, aiCount, mpu)
                  if (aiCount === 0 && mpu === 0) {
                    __metrics.TN++
                  } else {
                    if (_positive) {
                      __metrics.TP++
                    } else {
                      if ((aiCount + 1) >= mpu) {
                        __metrics.FP++
                      } else {
                        __metrics.FN++
                      }
                    }
                  }
                }
              }
            }
          })

          _metrics.push(__metrics)
        })

        const _zero = {
          threshold: 0,
          TP: _metrics[0].TP + _metrics[0].FN,
          FP: _metrics[0].FP + _metrics[0].TN,
          FN: 0,
          TN: 0
        }

        _metrics.push(_zero)

        _metrics.forEach(__metrics => {
          __metrics.total = __metrics.TP + __metrics.FP + __metrics.FN + __metrics.TN
          __metrics.sensitivity = __metrics.TP / (__metrics.TP + __metrics.FN)
          __metrics.specificity = __metrics.TN / (__metrics.FP + __metrics.TN)
          __metrics.accuracy = (__metrics.TP + __metrics.TN) / __metrics.total
          __metrics.NPV = __metrics.TN / (__metrics.TN + __metrics.FN)
          __metrics.FPR = __metrics.FP / (__metrics.TN + __metrics.FP)
          __metrics.TPR = __metrics.TP / (__metrics.TP + __metrics.FN)
          __metrics.F1 = (2 * (__metrics.sensitivity * __metrics.TPR)) / (__metrics.sensitivity + __metrics.TPR)
          __metrics.recall = __metrics.sensitivity
          __metrics.precision = __metrics.TP / (__metrics.TP + __metrics.FP)
          __metrics.error = (__metrics.FP + __metrics.FN) / __metrics.total
          __metrics._95_CI = 1.96 * Math.sqrt((__metrics.error * (1 - __metrics.error)) / __metrics.total)
        })

        this.aiMetrics = _metrics.sort((a, b) => { return a.threshold - b.threshold })
      }
    },
    hasGroup (img, val) {
      var _filter = this.project.subgroups.find(sg => sg.name === val).definiation
      // console.log(_filter)
      var includeInFilter = false
      Object.keys(_filter).forEach(_key => {
        if (_key === 'Pathologies') {
          _filter[_key].forEach(path => {
            if (img.pathologies.has(path)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Tags') {
          _filter[_key].forEach(tag => {
            // console.log(img)
            if (img.tags.has(tag)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Diagnosis') {
          _filter[_key].forEach(dia => {
            if (img.diagnoses.has(dia)) {
              includeInFilter = true
            }
          })
        } else if ((img.image.metadata && img.image.metadata[0] && img.image.metadata[0] && img.image.metadata[0].values.find(_meta => _meta.name === _key) && _filter[_key].includes(img.image.metadata[0].values.find(_meta => _meta.name === _key).value))) {
          includeInFilter = true
        }
      })
      // // console.log('sg', inc ludeInFilter)
      return includeInFilter
    }
  },
  watch: {
    getAi (_new) {
      if (_new) {
        this.imgAiData = this.images.aiImages.filter(img => (!img.image.removed.find(p => p.project === this.project._id)))
        this.getChartData()
        this.calcMetrics()
      }
    },
    projectImagesLoaded (_new) {
      if (_new) {
        this.proImg = this._project.projectImages
        this.getChartData()
        this.calcMetrics()
      }
    },
    imagesLoaded (_new) {
      if (_new) {
        this.imgData = this.images.labelledImages.filter(img => (!img.image.removed.find(p => p.project === this.project._id)))
        this.getChartData()
        this.calcMetrics()
      }
    },
    rejectWatch (_new) {
      if (_new) {
        this.rejections = this._project.rejects
        this.getChartData()
        this.calcMetrics()
      }
    },
    dataLoaded (_new) {
      // console.log(_new)
    },
    filterChoice () {
      this.calcMetrics()
      this.getChartData()
    }
  }
}
</script>

<style>
.bottom-to-top {
  writing-mode: vertical-lr;
  text-orientation: mixed;
}
.card-body .tabs {
  height: 100% !important;
}
.card-body .tabs .tab-pane {
  height: 100% !important;
}
</style>
