<template>
  <b-list-group flush :key="rerender">
    <b-list-group-item :href="'#phase-' + phase.id" @click.prevent="selectPhase(phase.id)"
      v-for="(phase, $index) in phases[studyType]" :key="$index" :active="phase.id === active">
      <b-row v-if="phase.id !== 2" class="my-0">
        <b-col cols="2" class="pr-0">
          <font-awesome-icon :icon="phase.icon" size="2x" class="text-white" />
        </b-col>
        <b-col class="d-flex flex-column">
          <b-row class="mb-1">
            <b-col cols="11" class="pl-0">
              <h6 class="color-white pt-1 mb-0 d-inline-block text-truncate" style="width: calc(100% - 0.25rem);">
                {{ phase.name }}
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400"
                  v-if="[1, 3, 4, 6, 7, 9, 10, 11].includes(phase.id)">
                  <span v-if="1 === phase.id && !projectimagesWatch">{{ (projectImages ? projectImages.length : 0) }}</span>
                  <span v-if="1 === phase.id && projectimagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-else-if="6 === phase.id && !aiImagesWatch">{{ processed ? processed :0  }}</span>
                  <span v-if="6 === phase.id && aiImagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-else-if="7 === phase.id  && !aiImagesWatch">{{ processed ? processed :0  }}</span>
                  <span v-if="7 === phase.id && aiImagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-else-if="3 === phase.id && !imagesWatch">{{ fully_annotated.length + partially_annotated.length + moderatedCount.length }} </span>
                  <span v-if="3 === phase.id && imagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-else-if="4 === phase.id  && !imagesWatch">{{ partialAgreement.length + fullDisagreement.length }} </span>
                  <span v-if="4 === phase.id && imagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-else-if="9 === phase.id && !loading">{{ members.length }} </span>
                  <span v-if="9 === phase.id && loading" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                </b-badge>

              </h6>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 1">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Data source: <strong class="text-white">{{ list[0].source.charAt(0).toUpperCase() +
                    list[0].source.slice(1) }}</strong><br />
                  Data location: <strong class="text-white">{{ projectType === 'project' ? (list.length > 0 ? 'On-premise'
                    : 'Unknown') : 'Cloud' }}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  Received: <strong class="text-white">{{ (projectImages ? projectImages.length : 0) }}</strong><br />
                  Rejected: <strong class="text-white"> {{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                  <b-progress height="5px" striped variant="success" class="mt-2 w-50"
                    :max="list.length === 163 ? 174 : list.length" :value="list.length" />
                </small></p>
            </b-col>
          </b-row>
          <!-- <b-row class="mb-1" v-if="phase.id === 2">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  De-identification: <strong class="text-white">No</strong><br />
                  Image Filters: <strong class="text-white">Chest AP / PA</strong><br />
                  Image Correction: <strong class="text-white">Greyscale</strong><br />
                  Scale, Straighten &amp; Crop: <strong class="text-white">Yes, 1024x1024</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  Accepted: <strong class="text-white">{{ list.length === 163 ? 174 : list.length }}</strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50"
                :max="list.length === 163 ? 174 : list.length" :value="list.length" />
            </b-col>
          </b-row> -->
          <b-row class="mb-1" v-if="phase.id === 3">
            <b-col cols="5">
              <p class="mb-0 line-height-1">
                <small>
                  Inter-observer Variability: <strong class="text-white">{{ project_IOV >= 0 ? (project_IOV * 100).toFixed(2) +'%' : 'Not Enough Data'}}</strong><br />
                  Fully Annotated: <strong class="text-white">{{ fully_annotated.length }}</strong><br />
                  Partially Annotated: <strong class="text-white">{{ partially_annotated.length }}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1">
                <small>
                  Not Annotated: <strong class="text-white">{{ (projectImages ? projectImages.length : 0) - fully_annotated.length -partially_annotated.length - moderatedCount.length  }}</strong><br />
                  Moderated: <strong class="text-white">{{ moderatedCount.length }}
                  ({{  moderatedCount.length === 0 ? 0: Math.round(moderatedCount.length / (moderatedCount.length + fully_annotated.length + partially_annotated.length  - ( rejected[4] ? rejected[4] :0 ) - (rejected[3] ? rejected[3] :0)) * 10000) / 100 }}%)</strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50" :max="projectImages ? projectImages.length : 0" :value="full_annotated + partial_annotated" />
            </b-col>
          </b-row>
          <!-- && list.length === 200" -->
          <b-row class="mb-1" v-if="phase.id === 4">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                Full Agreement: <strong class="text-white">{{ fully_annotated.length - partialAgreement.length - fullDisagreement.length - ( rejected[4] ? rejected[4] :0 ) - (rejected[3] ? rejected[3] :0) }}</strong><br />
                Partial Disagreement: <strong class="text-white">{{ partialAgreement.length }}</strong><br />
                Full Disagreement: <strong class="text-white">{{ fullDisagreement.length }}</strong><br />
              </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1"><small>
                Moderated: <strong class="text-white">{{ moderatedCount.length }}
                  ({{ moderatedCount.length === 0 ? 0:   Math.round(moderatedCount.length / (moderatedCount.length + fully_annotated.length - ( rejected[4] ? rejected[4] :0 ) - (rejected[3] ? rejected[3] :0)) * 10000) / 100 }}%)</strong><br />
                Rejected: <strong class="text-white"> {{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
              </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50" :max="partial_disagreements + full_disagreements" :value="moderated" />
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 12 && list[0].enable_training">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                Training configuration saved: <strong class="text-white">True</strong><br />
                Pathologies Selected: <strong class="text-white">0</strong><br />
                Diagnoses Selected: <strong class="text-white">0</strong><br />
              </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1"><small>
                Training status: <strong class="text-white"> Pending</strong><br />
                Training stats: <strong class="text-white"> Pending</strong><br />
              </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 6">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Available: <strong class="text-white">{{ fully_annotated.length + moderatedCount.length - processed}}</strong><br />
                  Submitted <strong class="text-white">{{processed}}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1"><small>
                Processed: <strong class="text-white">{{processed}}</strong><br />
                  Failed: <strong class="text-white">0</strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50"
                :max="list.length === 163 ? 174 : list.length" :value="list.length" />
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 7">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  TP: <strong class="text-white">{{ confusionMatrix.truePositive }} {{ '('+ (confusionMatrix.truePositive === 0 ? 0 : (confusionMatrix.truePositive / confusedSum)*100).toFixed(2)+ '%)' }} </strong><br />
                  FP: <strong class="text-white">{{ confusionMatrix.falsePositive }}  {{ '('+ (confusionMatrix.falsePositive === 0 ? 0 :  (confusionMatrix.falsePositive  / confusedSum)*100).toFixed(2)+ '%)' }} </strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" >
              <p class="mb-0 line-height-1"><small>
                  FN: <strong class="text-white">{{ confusionMatrix.falseNegative}}  {{ '('+ (confusionMatrix.falseNegative === 0 ? 0 : (confusionMatrix.falseNegative / confusedSum)*100).toFixed(2)+ '%)' }} </strong><br />
                  TN: <strong class="text-white">{{ confusionMatrix.trueNegative}}  {{ '('+ (confusionMatrix.trueNegative === 0 ? 0 : (confusionMatrix.trueNegative / confusedSum)*100).toFixed(2)+ '%)' }} </strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 8">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Sensitivity: <strong class="text-white">{{ ((confusionMatrix.truePositive + confusionMatrix.falseNegative) === 0 ? 0 : confusionMatrix.truePositive / (confusionMatrix.truePositive + confusionMatrix.falseNegative) *100).toFixed(2)+ '%' }}</strong><br />
                  Specificity: <strong class="text-white">{{ ((confusionMatrix.trueNegative + confusionMatrix.falsePositive) === 0 ? 0 : confusionMatrix.trueNegative / (confusionMatrix.trueNegative + confusionMatrix.falsePositive) *100).toFixed(2)+ '%' }}</strong><br />
                  Area under Curve: <strong class="text-white">{{ auc.toFixed(4) }}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  PPV: <strong class="text-white">{{ (confusionMatrix.truePositive + confusionMatrix.falsePositive) !== 0 ? (confusionMatrix.truePositive / (confusionMatrix.truePositive + confusionMatrix.falsePositive)).toFixed(4) : 0}}</strong><br />
                  NPV: <strong class="text-white">{{  (confusionMatrix.falseNegative + confusionMatrix.trueNegative) !== 0 ? (confusionMatrix.trueNegative / (confusionMatrix.falseNegative + confusionMatrix.trueNegative)).toFixed(4) : 0 }}</strong><br />
                  F1: <strong class="text-white"> {{ (((2*confusionMatrix.truePositive) + confusionMatrix.falsePositive + confusionMatrix.falseNegative))!== 0 ?  ((2*(confusionMatrix.truePositive) / (((2*confusionMatrix.truePositive) + confusionMatrix.falsePositive + confusionMatrix.falseNegative))) *100).toFixed(2)+ '%' : 0}}</strong><br />
                </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 11">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Concordance: <strong class="text-white">155 ({{ Math.round(155 / 163 * 10000) / 100 }}%)</strong><br />
                  Deviation: <strong class="text-white">1 ({{ Math.round(getStandardDeviation([155, 154]) * 100) / 100
                  }}%)</strong><br />
                </small></p>
            </b-col>reloadList
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  Discordance: <strong class="text-white">8 ({{ Math.round(8 / 163 * 10000) / 100 }}%)</strong><br />
                </small></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { IOVThesholds } from '../../../helpers/iovthresholds'
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { apiRoute } from './../../../helpers'

export default {
  props: {
    list: {
      type: Array,
      default: () => { return [] }
    },
    active: {
      type: Number,
      default: null
    },
    studyType: {
      type: String,
      default: 'retrospective'
    },
    projectType: {
      type: String,
      default: 'project'
    }
  },
  data () {
    return {
      phases: {
        retrospective: [
          { id: 10, stub: 'setup', name: 'AI Evaluation details', icon: 'sitemap' },
          { id: 5, stub: 'setup', name: 'AI Evaluation set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          // { id: 2, stub: 'preprocessing', name: 'Data pre-processing', icon: 'clone' },
          { id: 3, stub: 'annotation', name: 'Data annotation', icon: 'crop' },
          { id: 4, stub: 'truth', name: 'Reference standard', icon: 'check-double' },
          { id: 12, stub: 'training', name: 'AI Model Training', icon: 'users-cog' },
          { id: 6, stub: 'run', name: 'AI Processing', icon: 'hourglass-start' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ],
        prospective: [
          { id: 5, stub: 'setup', name: 'AI Evaluation set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          { id: 10, stub: 'feedback', name: 'Inference feedback', icon: 'crop' },
          { id: 11, stub: 'moderation', name: 'Feedback moderation', icon: 'check-double' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ]
      },
      rejected: {},
      annotated_images: [],
      projectIOV: 0,
      full_disagreements: 0,
      partial_disagreements: 0,
      full_annotated: 0,
      partial_annotated: 0,
      not_annotated: 0,
      moderated: 0,
      unmoderated: 0,
      totalProjectImages: 0,
      memCount: 0,
      rejections: [],
      rerender: 0
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    }),
    ...mapState('labelledImagesSubmission', {
      images: (state) => state.labelledImages,
      imagesWatch: (state) => state.loading,
      aiImages: (state) => state.aiImages,
      aiImagesWatch: (state) => state.aiLoading
    }),
    ...mapState('projectSubmission', {
      projectImages: (state) => state.projectImages,
      projectimagesWatch: (state) => state.loadingProjectImages,
      rejects: (state) => state.rejects,
      loadingRejects: (state) => state.loadingRejects,
      curProject: (state) => state.selectedProjectId
    }),
    ...mapState('usersSubmission', ['loading', 'members']),
    projectId () {
      if (this.members && this.members.length > 0 && this.list && this.list[0]) {
        return this.list[0]._id
      } else {
        return null
      }
    },
    readers () {
      if (this.members && this.members.length > 0 && this.list && this.list[0]) {
        const _project = this.list[0]._id
        const _readers = []
        this.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    moderators () {
      if (this.members && this.members.length > 0 && this.list[0]) {
        const _project = this.list[0]._id
        const _readers = []
        this.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Moderator') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    imagesLoad () {
      return this.projectimagesWatch
    },
    rejectLoad () {
      return this.loadingRejects
    },
    labelledLoad () {
      return this.imagesWatch
    },
    aiLoad () {
      return this.aiImagesWatch
    },
    reloadList () {
      return this.curProject
    },
    fully_annotated () {
      var temp = []
      if (this.readers.length) {
        this.images.forEach(img => {
          if (!img.image.removed.find(p => p.project === this.list[0]._id)) {
            if (img.pathologies.length > 0 && img.users.length === this.readers.length) {
              temp.push(1)
            }
          }
        })
      }
      return temp
    },
    partially_annotated () {
      var temp = []
      if (this.readers.length) {
        this.images.forEach(img => {
          if (!img.image.removed.find(p => p.project === this.list[0]._id)) {
            if (img.pathologies.length > 0 && img.users.length < this.readers.length && img.users.length > 0) {
              temp.push(1)
            }
          }
        })
      }
      return temp
    },
    partialAgreement () {
      var temp = []
      if (this.readers.length) {
        this.images.forEach(img => {
          if (!img.image.removed.find(p => p.project === this.list[0]._id)) {
            if (img.pathologies.length > 0 && img.users.length >= this.readers.length) {
              if (img.image.accuracy.find(_acc => _acc.project === this.list[0]._id)) {
                var acc
                if (img.image.accuracy.filter(a => a.project === this.list[0]._id).length > 0) {
                  acc = img.image.accuracy.filter(a => a.project === this.list[0]._id).slice(-1)[0].value
                } else {
                  acc = 0
                }
                var _acc = []
                if (img.otherImages && img.otherImages.length > 0 && img.image.image_set && img.image.image_set.name) {
                  img.otherImages.forEach(_img => {
                    const _accuracy = _img.image.accuracy.filter(a => a.project === this.list[0]._id)
                    if (_accuracy && _accuracy.length > 0) {
                      _acc.push(_accuracy.slice(-1)[0].value)
                    }
                  })
                }
                // console.log('acc', acc)
                _acc.push(acc)
                // console.log('all acc part list', _acc)
                _acc = _acc.filter(a => a !== 0)
                if (_acc.length > 0) {
                  // console.log('av  acc', _acc.reduce((a, b) => a + b) / _acc.length)
                  acc = _acc.reduce((a, b) => a + b) / _acc.length
                } else {
                  acc = 0
                }
                if (acc > IOVThesholds().fullAgreement && acc <= IOVThesholds().partialAgreement) {
                  temp.push(acc)
                }
              }
            }
          }
        })
      }
      return temp
    },
    fullDisagreement () {
      var temp = []
      if (this.readers.length) {
        this.images.forEach(img => {
          if (!img.image.removed.find(p => p.project === this.list[0]._id)) {
            if (img.pathologies.length > 0 && img.users.length >= this.readers.length) {
              if (img.image.accuracy.find(_acc => _acc.project === this.list[0]._id)) {
                var acc
                if (img.image.accuracy.filter(a => a.project === this.list[0]._id).length > 0) {
                  acc = img.image.accuracy.filter(a => a.project === this.list[0]._id).slice(-1)[0].value
                } else {
                  acc = 0
                }
                var _acc = []
                if (img.otherImages && img.otherImages.length > 0 && img.image.image_set && img.image.image_set.name) {
                  img.otherImages.forEach(_img => {
                    const _accuracy = _img.image.accuracy.filter(a => a.project === this.list[0]._id)
                    if (_accuracy && _accuracy.length > 0) {
                      _acc.push(_accuracy.slice(-1)[0].value)
                    }
                  })
                }
                // console.log('acc', acc)
                _acc.push(acc)
                // console.log('all acc', _acc)
                _acc = _acc.filter(a => a !== 0)
                if (_acc.length > 0) {
                  // console.log('av  acc', _acc.reduce((a, b) => a + b) / _acc.length)
                  acc = _acc.reduce((a, b) => a + b) / _acc.length
                } else {
                  acc = 0
                }
                if (acc > IOVThesholds().partialAgreement) {
                  temp.push(acc)
                }
              }
            }
          }
        })
      }
      return temp
    },
    project_IOV () {
      var temp = []
      var sum = 0
      if (this.readers.length) {
        this.images.forEach(img => {
          if (!img.image.removed.find(p => p.project === this.list[0]._id)) {
            if (img.pathologies.length > 0 && img.users.length >= this.readers.length) {
              if (img.image.accuracy.find(_acc => _acc.project === this.list[0]._id)) {
                var acc
                if (img.image.accuracy.filter(a => a.project === this.list[0]._id).length > 0) {
                  acc = img.image.accuracy.filter(a => a.project === this.list[0]._id).slice(-1)[0].value
                } else {
                  acc = 0
                }
                var _acc = []
                if (img.otherImages && img.otherImages.length > 0 && img.image.image_set && img.image.image_set.name) {
                  img.otherImages.forEach(_img => {
                    const _accuracy = _img.image.accuracy.filter(a => a.project === this.list[0]._id)
                    if (_accuracy && _accuracy.length > 0) {
                      _acc.push(_accuracy.slice(-1)[0].value)
                    }
                  })
                }
                // console.log('acc', acc)
                _acc.push(acc)
                // console.log('all acc', _acc)
                _acc = _acc.filter(a => a !== 0)
                if (_acc.length > 0) {
                  // console.log('av  acc', _acc.reduce((a, b) => a + b) / _acc.length)
                  acc = _acc.reduce((a, b) => a + b) / _acc.length
                } else {
                  acc = 0
                }
                sum += acc
                temp.push(acc)
              }
            }
          }
        })
      }
      return sum / temp.length
    },
    moderatedCount () {
      var temp = []
      if (this.readers.length) {
        this.images.forEach(img => {
          if (!img.image.removed.find(p => p.project === this.list[0]._id)) {
            if (img.pathologies.length > 0 && img.users.length === this.readers.length + this.moderators.length) {
              var modFlag = false
              img.users.forEach(usr => {
                // (usr.projectRole.find(pr => pr.project === this.list[0]._id).role === 'Moderator')
                if (usr.projectRole.find(pr => pr.project === this.list[0]._id) && usr.projectRole.find(pr => pr.project === this.list[0]._id).role === 'Moderator') {
                  modFlag = true
                }
              })
              if (modFlag) {
                temp.push(1)
              }
            }
          }
        })
      }
      return temp
    },
    processed () {
      return this.aiImages.filter(img => (!img.image.removed.find(p => p.project === this.list[0]._id))).length
    },
    confusionMatrix () {
      const _matrix = {
        truePositive: 0,
        falsePositive: 0,
        falseNegative: 0,
        trueNegative: 0
      }
      this.images.forEach(img => {
        if (!img.image.removed.find(p => p.project === this.list[0]._id)) {
          const _thisImage = this.projectImages.find(_record => _record._id === img.image._id)
          const _moderated = img.users.filter(_record => _record.projectRole.find(__record => __record.project === this.projectId && __record.role === 'Moderator'))
          // console.log('Moderated', _moderated)
          let _users = this.readers.map(_user => _user._id)
          if (_moderated.length > 0) {
            _users = this.moderators.map(_user => _user._id)
          }
          if (img.pathologies.length > 0 && img.users.length >= this.readers.length && _thisImage) {
            var mpu = 0
            // var pathCount = 0
            img.pathologies.filter(_record => _users.indexOf(_record.created_by) >= 0).forEach(p => {
              var runningPath = 0
              Object.keys(p).forEach(pkey => {
                if (pkey !== 'created_by') {
                  runningPath++
                }
              })
              // pathCount += runningPath
              mpu = mpu < runningPath ? runningPath : mpu
            })
            var aiImg = this.aiImages.find(i => img.image._id === i.image._id)
            // // console.log('ai', aiImg)
            if (aiImg) {
              const _aiAccuracy = _thisImage.ai_accuracy.find(_record => _record.project === this.projectId)
              const _positive = _aiAccuracy && _aiAccuracy.value ? _aiAccuracy.value > 0.00000001 : false
              // console.log('AI Accuracy', _aiAccuracy)
              var aiCount = 0
              Object.keys(aiImg.pathologies[0]).forEach(aiP => {
                if (aiP !== 'created_by' && aiImg.pathologies[0][aiP].name !== 'heart' && aiImg.pathologies[0][aiP].name !== 'apchest') {
                  if (aiImg.pathologies[0][aiP].confidence >= 0.8) {
                    aiCount++
                  }
                }
              })
              // // console.log('abc', pathCount)
              // // console.log('ai', aiCount)
              // aiCount === pathCount ? 'True Positive' : aiCount > pathCount ? 'False Positive' : 'False Negative'
              // // console.log('ai', aiCount)
              // // console.log('max', mpu)
              if (aiCount === 0 && mpu === 0) {
                _matrix.trueNegative++
              } else {
                if (_positive) {
                  _matrix.truePositive++
                } else {
                  if ((aiCount + 1) >= mpu) {
                    _matrix.falsePositive++
                  } else {
                    _matrix.falseNegative++
                  }
                }
              }
            }
          }
        }
      })
      return _matrix
    },
    metrics () {
      const _metrics = []

      if (this.aiImages && this.aiImages.length > 0) {
        const __metrics = {
          threshold: 0.5,
          TP: this.confusionMatrix.truePositive,
          FP: this.confusionMatrix.falsePositive,
          FN: this.confusionMatrix.falseNegative,
          TN: this.confusionMatrix.trueNegative
        }

        _metrics.push(__metrics)
        const _original = __metrics
        let _previous = _original

        const _temp = []
        for (let i = 0.55; i < 1; i += 0.05) {
          _temp.push({
            threshold: Math.round(i * 100) / 100
          })
        }

        _temp.forEach(__record => {
          const __metrics = {
            threshold: __record.threshold,
            TP: Math.round(_previous.TP),
            FP: 0,
            FN: 0,
            TN: Math.round(_previous.TN)
          }

          __metrics.TP = __metrics.TP < 0 ? 0 : __metrics.TP
          __metrics.FP = _previous.FP - (__metrics.TN - _previous.TN)
          __metrics.FN = _previous.FN + (_previous.TP - __metrics.TP)

          if (__metrics.FP < 0) {
            const _diff = Math.abs(__metrics.FP)
            __metrics.TN -= _diff
            __metrics.FP = 0
          }

          _metrics.push(__metrics)
          _previous = __metrics
        })

        const __temp = []
        for (let i = 0.45; i > 0.05; i -= 0.05) {
          __temp.push({
            threshold: Math.round(i * 100) / 100
          })
        }

        _previous = _original
        __temp.forEach(__record => {
          const __metrics = {
            threshold: __record.threshold,
            TP: Math.round(_previous.TP),
            FP: 0,
            FN: 0,
            TN: Math.round(_previous.TN)
          }

          __metrics.TN = __metrics.TN < 0 ? 0 : __metrics.TN
          __metrics.FN = _previous.FN - (__metrics.TP - _previous.TP)
          __metrics.FP = _previous.FP + (_previous.TN - __metrics.TN)

          if (__metrics.FN < 0) {
            const _diff = Math.abs(__metrics.FN)
            __metrics.TP -= _diff
            __metrics.FN = 0
          }

          _metrics.push(__metrics)
          _previous = __metrics
        })

        const _zero = {
          threshold: 0,
          TP: _original.TP + _original.FN,
          FP: _original.FP + _original.TN,
          FN: 0,
          TN: 0
        }

        _metrics.push(_zero)

        _metrics.forEach(__metrics => {
          __metrics.total = __metrics.TP + __metrics.FP + __metrics.FN + __metrics.TN
          __metrics.sensitivity = __metrics.TP / (__metrics.TP + __metrics.FN)
          __metrics.specificity = __metrics.TN / (__metrics.FP + __metrics.TN)
          __metrics.accuracy = (__metrics.TP + __metrics.TN) / __metrics.total
          __metrics.NPV = __metrics.TN / (__metrics.TN + __metrics.FN)
          __metrics.FPR = __metrics.FP / (__metrics.TN + __metrics.FP)
          __metrics.TPR = __metrics.TP / (__metrics.TP + __metrics.FN)
          __metrics.F1 = (2 * (__metrics.sensitivity * __metrics.TPR)) / (__metrics.sensitivity + __metrics.TPR)
          __metrics.recall = __metrics.sensitivity
          __metrics.precision = __metrics.TP / (__metrics.TP + __metrics.FP)
          __metrics.error = (__metrics.FP + __metrics.FN) / __metrics.total
          __metrics._95_CI = 1.96 * Math.sqrt((__metrics.error * (1 - __metrics.error)) / __metrics.total)
        })

        return _metrics.sort((a, b) => { return a.threshold - b.threshold })
      } else {
        return []
      }
    },
    auc () {
      const _thresholds = JSON.parse(JSON.stringify(this.metrics.filter(__metrics => __metrics.threshold !== 0)))
      const _metrics = []

      if (_thresholds && _thresholds.length > 0) {
        _metrics.push({
          threshold: 0,
          FPR: 1,
          TPR: 1,
          AUC: (1 - _thresholds[0].FPR) * 1
        })

        for (let i = 0; i < _thresholds.length; i++) {
          _metrics.push({
            threshold: _thresholds[i].threshold,
            FPR: _thresholds[i].FPR,
            TPR: _thresholds[i].TPR,
            AUC: (_thresholds[i].FPR - (_thresholds[i + 1] ? _thresholds[i + 1].FPR : 0) - 0.0045) * _thresholds[i].TPR
          })
        }
      }

      let _sum = 0
      if (_metrics.filter(_record => _record.FPR !== null && _record.TPR !== null).length > 1) {
        _metrics.forEach(_metric => {
          _sum += _metric.AUC
        })
      }

      return _sum
    },
    confusedSum () {
      return this.confusionMatrix.truePositive + this.confusionMatrix.falsePositive + this.confusionMatrix.falseNegative + this.confusionMatrix.trueNegative
    }
  },
  watch: {
    aiLoad (_new) {
      if (_new && this.curProject === this.list[0]._id) {
        // var projectImages = this.projectImages
        // this.totalProjectImages = projectImages ? projectImages.length : 0
        this.rerender++
      }
    },
    imagesLoad (_new) {
      if (_new && this.curProject === this.list[0]._id) {
        // console.log('pull img', _new)
        var projectImages = this.projectImages
        this.totalProjectImages = projectImages ? projectImages.length : 0
        this.rerender++
      }
    },
    rejectLoad (_new) {
      if (!_new && this.curProject === this.list[0]._id) {
        // // console.log(this.rejects)
        this.rejections = this.rejects
        this.rerender++
      }
    },
    labelledLoad (_new) {
      if (_new && this.curProject === this.list[0]._id) {
        this.annotated_images = this.images.filter(img => (!img.image.removed.find(p => p.project === this.list[0]._id)))
        this.rerender++
      }
    },
    reloadList (_new) {
      if (this.list[0]._id === _new) {
        if (!this.list[0].enable_training) {
          this.phases.retrospective = this.phases.retrospective.filter(ph => ph.id !== 12)
        }
        this.getMembers(this.list[0]._id)
        this.fetchLabelledImages2(this.list[0]._id)
        // console.log('pul')
        this.fetchAILabelledImages(this.list[0]._id)
        this.getProjectImages2(this.list[0]._id)
        this.getRejections2(this.list[0]._id)
        this.getAllImages2(this.list[0]._id)
        this.rerender++
      }
    }

  },
  methods: {
    selectPhase: function (id) {
      this.$emit('phase-selected', id)
    },
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2', 'fetchAILabelledImages']),
    ...mapActions('usersSubmission', {
      getMembers: 'getMembers'
    }),
    ...mapActions('projectSubmission', ['getProjectImages2', 'getRejections2', 'getAllImages2']),
    formatDate: function (date) {
      return moment(date).fromNow()
    },
    formatFullDate: function (date) {
      return moment(date).format('D MMM YYYY, HH:mm')
    },
    getConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return ''
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return ''
      } else if (name.indexOf('other') > -1) {
        return ''
      } else {
        return ''
      }
    },
    getIcon: function (name) {
      if (name.indexOf('high') > -1) {
        return 'circle'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'circle'
      } else {
        return 'heartbeat'
      }
    },
    getIndicator: function (name) {
      if (name.indexOf('high') > -1) {
        return 'high'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'high'
      } else {
        return 'nothing'
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        if (result.attachments[0].response.bounding.path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].response.bounding.path
        } else {
          return result.attachments[0].response.bounding.path
        }
      } else {
        return null
      }
    },
    getStandardDeviation: function (array) {
      const n = array.length
      const mean = array.reduce((a, b) => a + b) / n
      return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
    }
  },
  mounted () {
    if (this.list[0] && this.curProject === this.list[0]._id) {
      if (!this.list[0].enable_training) {
        this.phases.retrospective = this.phases.retrospective.filter(ph => ph.id !== 12)
      }
      this.getMembers(this.list[0]._id)
      this.fetchLabelledImages2(this.list[0]._id)
      this.getProjectImages2(this.list[0]._id)
      // console.log('pul')
      this.fetchAILabelledImages(this.list[0]._id)
      this.getRejections2(this.list[0]._id)
      this.getAllImages2(this.list[0]._id)
    }
  }
}
</script>
